.root {
  color: #fff;
  font-size: larger;
  border-radius: 10px;
}
.root {
  background-color: #4285f4;
}
.root a {
  height: 3em;
  display: flex;
  align-items: center;
  column-gap: 1em;
}
.root img {
  margin-left: 1em;
  display: block;
  width: 36px;
  height: 36px;
}
.root a,
.root a:visited {
  text-decoration: none;
}
