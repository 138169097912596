/* autoprefixer grid: autoplace */
.root {
}
.root a {
  text-decoration: none;
}
.root h2 {
  margin: 0;
  max-height: 2.3em;
  overflow: hidden;
}
.meta {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2em;
  grid-template-rows: 1fr;
  padding: 1em;
  height: calc(2em + 1.15em + 2.3em);
  background-color: var(--card-background-color);
  color: var(--card-text-color);
}
.image {
  position: relative;
  height: 15em;
  overflow-y: hidden;
  background-size: cover;
}
.where {
  height: 1.15em;
  overflow: hidden;
}
.when {
  font-size: large;
  text-align: center;
}
.day {
  font-size: x-large;
}
.series {
  white-space: nowrap;
  font-size: x-small;
}
.unpublished .image,
.unpublished .meta {
  opacity: 0.4;
}
.pick,
.status {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-weight: bold;
  padding: 0.5em;
  text-transform: uppercase;
}
.pick {
  width: 5em;
  font-size: small;
  text-align: center;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}
.status {
  width: 8em;
  font-size: x-small;
  text-align: right;
}
