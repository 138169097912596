.ad {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.placeholder {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  color: #333;
  background-color: #ddd;
  padding: 1em;
  text-align: center;
}
.adCode {
  height: 1em;
  margin-top: 1em;
}
.bad {
  width: 200px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  color: white;
  background-color: red;
  padding: 1em;
  text-align: center;
}
