.root {
  padding: 0.5em 0 0.5em 0;
  min-height: 2em;
  max-height: 400px;
  overflow: hidden;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
}
@media print {
  .root {
    display: none;
  }
}
.logo {
  text-align: center;
}
