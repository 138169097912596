.root {
}
.root a {
  text-decoration: none;
}
.name {
  font-weight: bold;
  font-size: 1.5em;
}
.meta {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2em;
  grid-template-rows: 1fr;
  padding: 1em;
  height: 4.5em;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
}
.image {
  position: relative;
  height: 15em;
  overflow-y: hidden;
  background-size: cover;
}