.root {
}
.heading,
.receipt,
.noPurchases {
  composes: spaced-element from '../standard.module.css';
}
.receipt > a {
  text-decoration: none;
}
.price,
.date {
  font-family: monospace;
}
.receiptNum {
  font-weight: bold;
}
