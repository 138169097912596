.root {
  width: 100%;
  --height: 0;
}
.root > iframe {
  display: block;
  border: none;
  margin: 0;
  outline: none;
  width: 100%;
  min-width: 100%;
}
.responsiveHeight {
  height: var(--mobileHeight);
}
@media (min-width: 480px) {
  .responsiveHeight {
    height: var(--tabletHeight);
  }
}
@media (min-width: 768px) {
  .responsiveHeight {
    height: var(--desktopHeight);
  }
}
@media print {
  .root {
    display: none;
  }
}
