.root {
}
.title {
  composes: spaced-element from './standard.module.css';
}
.address {
  composes: spaced-element from './standard.module.css';
}
.map {
  composes: spaced-bottom from './standard.module.css';
  width: 100%;
  height: 20em;
}

@media (min-width: 1200px) {
  .map {
    grid-area: map;
  }
}
