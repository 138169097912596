.root {
  position: relative;
}
.positionInline {
}
.positionRight {
  position: absolute;
  z-index: 10000;
  right: 0;
  top: 100%;
}
.positionLeft {
  position: absolute;
  left: 0;
  top: 100%;
}
