/* autoprefixer grid: autoplace */
.root {
  font-family: sans-serif;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 2em;
  row-gap: 1em;
}
.vybLogo {
  height: 2.5em;
  vertical-align: text-bottom;
  margin-bottom: -0.75em;
}
.vybLogo path {
  fill: currentColor;
}
.orEmail {
  padding-top: 2em;
  display: block;
  text-align: center;
  border-top: 1px solid #556a7a;
}
@media (min-width: 480px) {
  .root {
    max-width: 480px;
    margin: auto;
  }
  .container {
    margin: 2em;
    border: 1px solid black;
    border-radius: 10px;
  }
}
