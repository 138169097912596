@import '~normalize.css/normalize.css';
a,
a:hover {
  color: inherit;
}
:root {
  --body-background-color: #FFFFFF;
  --body-text-color: #393D3F;
  --button-background-color: #64929D;
  --button-text-color: #EAECED;
  --card-background-color: #393D3F;
  --card-text-color: #EAECED;
  --featured-background-color: #C6C5B9;
  --featured-background-border-color: #35342B;
  --featured-text-color: #393D3F;
  --footer-background-color: #EAECED;
  --footer-text-color: #393D3F;
  --header-background-color: #EAECED;
  --header-text-color: #393D3F;
  --sitenav-background-color: #556A7A;
  --sitenav-text-color: #EAECED;
}