.root {
  text-decoration: none;
}
.image {
  height: 12em;
  background-size: cover;
}
.content {
  min-height: 8em;
  padding: 2em;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
}
.content h2 {
  margin: 0;
}
.content p {
  line-height: 1.5em;
}
