.image {
  width: 100%;
  height: 56.25vw;
  overflow-y: hidden;
  background-size: cover;
}
.map {
  width: 100%;
  height: 56.25vw;
}
.layout > * {
  margin: 0.5em;
}
.layout > .image,
.layout > .map,
.layout > .events {
  margin: 0.5em 0;
}
.name {
  font-weight: bold;
  font-size: larger;
}
.about > p:first-child,
.about > p:last-child,
.marketingMessage > p:first-child,
.marketingMessage > p:last-child,
.parkingContent > p:first-child,
.parkingContent > p:last-child,
.hoursContent > p:first-child,
.hoursContent > p:last-child {
  margin: 0;
}
@media (min-width: 768px) {
  .layout > * {
    margin: 0;
  }
  .layout {
    display: grid;
    gap: 0.3em 1em;
    grid-template:
      'name name name' auto
      'marketingMessage marketingMessage marketingMessage' auto
      'image image image' auto
      'map about ads' auto
      'map address ads' auto
      'map hours ads' auto
      'map phone ads' auto
      'map url ads' auto
      'map email ads' auto
      'map socialLinks ads' auto
      'map parking ads' auto
      'map . ads' auto
      'events events ads' auto
      '. . ads' 1fr
      / 1fr 1fr 300px;
  }
  .name {
    grid-area: name;
    margin-left: 0;
    font-size: x-large;
  }
  .address {
    grid-area: address;
  }
  .hours {
    grid-area: hours;
  }
  .phone {
    grid-area: phone;
  }
  .url {
    grid-area: url;
  }
  .email {
    grid-area: email;
  }
  .marketingMessage {
    grid-area: marketingMessage;
    margin: 0;
  }
  .image {
    grid-area: image;
  }
  .socialLinks {
    grid-area: socialLinks;
  }
  .parking {
    grid-area: parking;
  }
  .about {
    grid-area: about;
  }
  .map {
    height: auto;
    min-height: 500px;
    grid-area: map;
  }
  .events {
    grid-area: events;
  }
  .ads {
    grid-area: ads;
  }
}
