/* autoprefixer grid: autoplace */
.root {
}
.layout {
  composes: layout-right-rail from '../standard.module.css';
}
.events {
  composes: layout-left-rail-grid from '../standard.module.css';
}
.noEvents {
  composes: spaced-element from '../standard.module.css';
}

.header {
  composes: spaced-bottom from '../standard.module.css';
  position: relative;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
}
.header a {
  text-decoration: none;
}
.shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2.3em 1fr 2.3em;
  min-height: 10em;
  z-index: 1;
  position: relative;
  padding: 1em;
}
.content h1 {
  margin: 0;
}
.description {
  margin-bottom: 1em;
}
.children {
  text-align: center;
}
.child {
  display: inline-block;
  margin-right: 0.5em;
}
.child:last-child {
  margin-right: 0;
}

.categoryCard {
  display: block;
  text-decoration: none;
  margin-bottom: 1em;
}
.categoryCard:last-child {
  margin-bottom: 0;
}
.image {
  height: 10em;
  width: 100%;
  background-size: cover;
  text-decoration: none;
}
.name {
  padding: 1em;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
}
.pagination {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
}
.page {
  display: inline-block;
  margin-right: 1em;
  border: 1px solid;
  padding: 1em;
  font-weight: bold;
  min-width: 6em;
  text-decoration: none;
  user-select: none;
  color: var(--body-text-color);
  border-color: var(--body-text-color);
}
.page:last-child {
  margin-right: 0;
}
.page svg {
  vertical-align: middle;
}
