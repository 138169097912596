/* autoprefixer grid: autoplace */
.root {
}
.root a {
  text-decoration: none;
}
.title {
  composes: spaced-element from './standard.module.css';
}
.image {
  composes: spaced-bottom from './standard.module.css';
  object-fit: contain;
  width: 100%;
  max-width: 768px;
  max-height: 768px;
}
.unpublished,
.cancelled,
.postponed,
.hidden,
.quarantine {
  font-weight: lighter;
  text-align: center;
  border: 1px solid;
  padding: 1em;
  margin-bottom: 0.5em;
}
.togglePicked,
.toggleHidden {
  composes: spaced-element from './standard.module.css';
}
.meta {
  composes: spaced-element from './standard.module.css';
}
.meta table {
  border-collapse: collapse;
  width: 100%;
}
.meta td {
  vertical-align: top;
  padding-bottom: 1rem;
  padding-left: 0.6rem;
}
.meta th {
  vertical-align: top;
}
.buy,
.promote {
  composes: spaced-element from './standard.module.css';
  display: block;
}
.map {
  composes: spaced-bottom from './standard.module.css';
  width: 100%;
  height: 20em;
}
.description {
  composes: spaced-element from './standard.module.css';
  font-size: larger;
}
.editMyEvent {
  display: block;
  composes: spaced-element from './standard.module.css';
}
.moreInSeries {
  display: block;
  composes: spaced-element from './standard.module.css';
}
.category {
  display: block;
}
.series {
  display: inline-block;
  width: 14em;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 1200px) {
  .root {
    display: grid;
    grid-template:
      'title title'
      'image col'
      'desc col'
      'moreInSeries moreInSeries'
      'map map'/ 1fr 1fr;
    grid-column-gap: 1em;
  }
  .title {
    grid-area: title;
  }
  .image {
    grid-area: image;
  }
  .col {
    grid-area: col;
  }
  .description {
    grid-area: desc;
  }
  .moreInSeries {
    grid-area: moreInSeries;
  }
  .map {
    grid-area: map;
  }
}
