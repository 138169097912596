.root {
  background-color: var(--featured-background-color);
  color: var(--featured-text-color);
  margin-bottom: 1em;
}
.container {
  composes: layout-full-width-grid from '../standard.module.css';
  margin: 0;
}
.header {
  display: flex;
  padding: 1em 0.5em 0 0.5em;
  margin-bottom: 0.8em;
  align-items: baseline;
  justify-content: space-between;
}
.title {
  font-weight: bold;
  font-size: 1.5em;
}
.promo {
  background-color: blue;
}
.message {
  padding-bottom: 0.5em;
}
.message > p {
  margin: 0 0.5em 0.5em 0.5em;
}
.dropdownmessage {
  background-color: var(--featured-background-color);
  color: var(--featured-text-color);
  margin-right: -0.5em;
  width: 20em;
  border: 1px solid transparent; /* for side-effect of fixing mysterious z-index issue */
  box-shadow: 0 0 2px var(--featured-background-border-color);
}
.dropdownmessage > p {
  padding: 0.5em;
}
