.root {
  padding: 0.8em;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  display: inline-block;
  text-decoration: none;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}
.root[disabled] {
  opacity: 0.5;
  cursor: inherit;
}
.root[disabled]:hover {
  color: var(--button-text-color);
}
