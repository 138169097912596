.root {
}
.root > * {
  margin-bottom: 1rem;
}
.layout {
  composes: layout-right-rail from '../standard.module.css';
}

.upcomingHighlighted {
  composes: layout-left-rail-grid from '../standard.module.css';
}
.noUpcomingHighlighted {
}
.homeCategories {
  composes: layout-full-width-grid from '../standard.module.css';
}
.addEvent {
  composes: spaced-element from '../standard.module.css';
}
.homeAnnouncemnts {
  background-color: #64929d;
  padding: 4px;
  color: #fff;
  font-family: 'Cabin', sans-serif;
  text-align: center;
}
.root .addEvent {
  display: block;
}
