.root {
}
.title {
  composes: spaced-element from '../standard.module.css';
}
.layout {
  composes: layout-right-rail from '../standard.module.css';
}
.events {
  composes: layout-left-rail-grid from '../standard.module.css';
}
.map {
  composes: spaced-bottom from '../standard.module.css';
  width: 100%;
  height: 20em;
}
.noEvents {
  composes: spaced-element from '../standard.module.css';
}
