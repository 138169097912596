.root {
}
.venuePicker {
}
.venuePicker ul {
  list-style-type: none;
  display: inline;
}
.venuePicker ul li.container {
  display: flex;
}
.venuePicker ul li.container * {
  padding-left: 10px;
}
.venuePicker ul li.container *:first-child {
  padding-left: 0;
}
