.root {
}
.events {
  composes: layout-full-width-grid from './standard.module.css';
}
.closeMatches {
  margin-top: 1em;
  margin-bottom: 1em;
}
.pagination {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
}
.page {
  display: inline-block;
  margin-right: 1em;
  border: 1px solid;
  padding: 1em;
  font-weight: bold;
  min-width: 6em;
  text-decoration: none;
  user-select: none;
  color: var(--body-text-color);
  border-color: var(--body-text-color);
}
.page:last-child {
  margin-right: 0;
}
.page svg {
  vertical-align: middle;
}
.disabled {
  opacity: 0.2;
  cursor: default;
}
.noEvents {
  composes: spaced-element from './standard.module.css';
}
