.root {
}
.heading {
  composes: spaced-element from '../standard.module.css';
  margin-top: 1em;
}
.categories {
  composes: layout-full-width-grid from '../standard.module.css';
}
.card {
  min-height: 2em;
}
.card a {
  text-decoration: none;
}
.thumbnail {
  height: 16em;
  overflow-y: hidden;
  background-size: cover;
}
.thumbnail img {
  width: 100%;
}
.meta {
  padding: 2em 1em 2em 1em;
  text-align: center;
}
.name {
  font-weight: bold;
  margin-bottom: 1em;
}
.blurb {
}
