.root {
}
.uploading {
  opacity: 0.2;
}
.root label {
  display: block;
}
.dropzoneContainer {
  display: block;
  border: 2px dashed #ccc;
  padding: 0.25em;
  margin-bottom: 0.5em;
}
.dropzone {
  min-height: 16em;
  display: inline-block;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}
.dropzone,
.dropzone img {
  cursor: pointer;
}
.dropzone input {
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 2;
}
.imageContainer {
  height: 1px;
  overflow-y: hidden;
}
.dropzone img {
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 0;
  max-height: 16em;
}
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
}
.selectButton,
.clearButton {
  display: block;
  background-color: #333;
  color: #fff;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
}
.noClear {
  opacity: 0.5;
}
.error {
  color: red;
}
