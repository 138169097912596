.root {
}
.root :global .DayPicker-Day,
.inputRoot :global .DayPicker-Day {
  border-radius: 0;
}
.root :global .DayPicker-Day--disabled {
  cursor: not-allowed;
}
.inputRoot {
  width: 100%;
}
.input {
  border: 1px solid hsl(0, 0%, 80%);
  min-height: 36px;
  border-radius: 4px;
  padding: 0 8px;
  width: calc(100% - 18px);
}
