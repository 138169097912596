.root {
  composes: spaced-element from './standard.module.css';
}
.root thead td {
  padding-bottom: 1em;
  text-align: right;
}
.root tfoot td {
  padding-top: 1em;
}
.root tbody td {
  padding: 0.2em 0;
}
.root thead td > *:last-child {
  padding-bottom: 0.5em;
  border-bottom: 1px solid currentColor;
}
.root td:nth-child(2) {
  text-align: right;
}
.image {
  max-width: 100px;
  max-height: 100px;
}
.meta {
  display: inline-block;
  padding: 0 0.5em;
}
.title,
.totalPrice {
  font-weight: bold;
}
.price,
.totalPrice {
  font-family: monospace;
}
@media (min-width: 480px) {
  .root {
    min-width: 480px;
  }
}
