/* autoprefixer grid: autoplace */
.root {
  max-width: 768px;
}
.root fieldset {
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.root textarea,
.root input[type='text'],
.root input[type='date'],
.root input[type='time'],
.root select {
  padding: 0.5em;
  font-size: 1.2em;
  margin-bottom: 0.1em;
  border: 1px solid #ccc;
  width: calc(100% - 1em);
}
.root input[type='checkbox'] {
  transform: scale(2);
  padding: 10px;
  margin: 0 1em;
}
.root label {
  user-select: none;
}
.root textarea {
  height: 10em;
}
.root button {
  font-size: 1.2em;
  padding: 0.5em;
}
.heading,
.subHeading,
.error {
  composes: spaced-element from '../standard.module.css';
}
.error {
  color: red;
  font-weight: bold;
}
@media (min-width: 480px) {
  .when {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
.when > div:first-child {
  text-align: center;
}
.when > div:last-child > div,
.additionalDatePicker > div:first-child {
  margin-top: 0.6em;
}
.deleteDate {
  margin: 0.6em;
  display: block;
  text-align: right;
}
.preamble {
  margin-bottom: 2em;
}
.root .submit {
  display: flex;
  justify-content: space-between;
}
.fieldLabel {
  composes: spaced-element from '../standard.module.css';
}
.root .fieldLabel {
  margin-top: 1rem;
}
.published {
}
.recaptcha {
}