/* autoprefixer grid: autoplace */
.root {
  display: grid;
  padding: 0.6em;
  padding-bottom: 1em;
  font-size: 20px;
  max-width: 1200px;
  margin: auto;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
}
@media print {
  .root {
    display: none;
  }
}
.root > div {
  margin-bottom: 0.4em;
  margin-right: 0.4em;
}
@supports (grid-gap: 1em) {
  .root {
    grid-row-gap: 0.5em;
  }
  .root > div {
    margin-bottom: 0;
    margin-right: 0;
  }
}
.hideFilters {
  grid-template:
    'text toggle search'
    / 3fr auto auto;
}
.showFilters {
  grid-template:
    'text  toggle search'
    'what  what   what'
    'where where  where'
    'when  when   when'
    / 3fr auto auto;
}
.hideFilters > .what,
.hideFilters > .when,
.hideFilters > .where {
  display: none;
}
.showFilters > .what,
.showFilters > .when,
.showFilters > .where {
  display: block;
}
.search2 {
  display: none;
}
@media (min-width: 768px) {
  .hideFilters,
  .showFilters {
    grid-template:
      'text text text  text  text text'
      'what what where where when when'
      / 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 0.5em;
  }
  .hideFilters > .what,
  .hideFilters > .when,
  .hideFilters > .where {
    display: block;
  }
  .search,
  .filterToggle {
    display: none;
  }
  .search2 {
    display: block;
  }
}
@media (min-width: 1200px) {
  .hideFilters,
  .showFilters {
    grid-template:
      'text what where when search'
      / 2fr 1.25fr 1.25fr 1.25fr auto;
  }
  .search {
    display: block;
  }
  .search2 {
    display: none;
  }
}
.hideFilters > .filterToggle,
.showFilters > .filterToggle {
  grid-area: toggle;
  background-color: inherit;
  color: inherit;
  user-select: none;
}
.hideFilters > .text,
.showFilters > .text {
  grid-area: text;
}
.hideFilters > .what,
.showFilters > .what {
  grid-area: what;
}
.hideFilters > .where,
.showFilters > .where {
  grid-area: where;
}
.hideFilters > .when,
.showFilters > .when {
  grid-area: when;
}
.hideFilters > .search,
.showFilters > .search {
  grid-area: search;
}
.text {
  display: grid;
  grid-template: 'input button' / 1fr auto;
  grid-column-gap: 0.5em;
}
.text > form {
  grid-area: input;
  border: 1px solid;
  border-radius: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: inherit;
}
.search2 {
  grid-area: button;
  background-color: blue;
}
.text input {
  color: inherit;
  display: block;
  width: 100%;
  padding: 10px 8px;
  background-color: transparent;
  border: 1px solid transparent;
}
.text input:focus {
  outline: none;
  border: 1px solid;
}
.text input::placeholder {
  color: inherit;
}
.search button,
.search2 button,
.filterToggle button {
  width: 100%;
  border: 0;
  padding: 0 8px;
  text-align: center;
  background-color: var(--header-background-color);
  color: inherit;
}
.dayPicker {
  width: 100%;
}
.what :global .SearchOptions--TopLevel {
  font-weight: bold;
}
.what :global .SearchOptions--Path {
  font-size: x-small;
  font-variant: small-caps;
  color: grey;
}
.what :global .SearchOptions__option--is-selected .SearchOptions--Path {
  color: hsl(0, 0%, 80%);
}
.when :global .SearchOptions__menu-list {
  max-height: inherit;
}
.searchIcon,
.toggleIconOpen,
.toggleIconClosed {
  font-size: 42px;
}
.toggleIconOpen {
  transform: rotate(180deg);
}
