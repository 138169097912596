.root {
  height: 100%;
  border: 1px solid;
  border-radius: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--header-text-color);
}
.root :global .SearchOptions__control {
  height: 100%;
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
}
.root :global .SearchOptions__control--is-focused,
.root :global .SearchOptions__control--is-focused:hover {
  border: 1px solid;
  border-radius: 0;
  margin-left: -1px;
  margin-right: -1px;
}
.root :global .SearchOptions__menu {
  color: black;
  z-index: 10000;
}
.root :global .SearchOptions__option--is-selected.SearchOptions__option--is-focused {
  opacity: 0.5;
}
.root :global .SearchOptions__multi-value {
  background-color: inherit;
  margin-left: 2px;
}
.root :global .SearchOptions__multi-value__label {
  font-size: 100%;
  color: inherit;
  padding: 0;
}
.root :global .SearchOptions__multi-value__remove {
  display: none;
}
.root :global .SearchOptions__placeholder {
  color: inherit;
  width: 100%;
}
.root :global .SearchOptions__indicator {
  padding-left: 2px;
  padding-right: 2px;
}
.root :global .SearchOptions__indicator-separator {
  display: none;
}
.root :global .SearchOptions__indicator,
.root :global .SearchOptions__indicator:hover {
  color: inherit;
}
.pillCount {
  margin-left: 0.25em;
  font-size: 0.6em;
  vertical-align: super;
}
