/* autoprefixer grid: autoplace */
.root {
  padding: 1em;
}
.root a {
  text-decoration: none;
}
.widgetCard,
.search,
.callToAction {
  margin-bottom: 1em;
}
.search {
  box-sizing: border-box;
}
.search * {
  box-sizing: inherit;
}
.search {
  display: grid;
  grid-template: 'input submit' / 1fr auto;
  padding: 0;
}
.input {
  grid-area: input;
}
.submit {
  grid-area: submit;
}
.search > * {
  align-self: center;
}
.input,
.submit {
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 0.5em;
  height: 3em;
  border: 1px solid #ccc;
  border-right: none;
}
.submit {
  background-color: black;
  color: white;
  text-align: center;
}
.widgetCard {
  display: inline-block;
  max-width: 100%;
}
.featured .meta {
  padding-top: 1em;
}
.featured .meta h3 {
  font-size: 1.3em;
}
.featured .thumbnail {
  height: 12em;
  max-height: 12em;
}
.backfilled {
  display: grid;
  grid-template: 'thumbnail meta' / 1fr 1fr;
  grid-gap: 0.5em;
  max-height: 6em;
}
.backfilled .thumbnail {
  grid-area: thumbnail;
}
.backfilled .meta {
  grid-area: meta;
  overflow: hidden;
  max-height: 6em;
}
.backfilled .meta h3 {
  font-size: 1em;
}
.meta h3 {
  margin: 0;
}
.date {
  font-size: 0.8em;
}
.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 6em;
  max-width: 100%;
  max-height: 100%;
}
.branding {
  font-size: 0.8em;
  text-align: right;
}
.callToAction {
  display: block;
  padding: 1em;
  text-align: center;
  font-weight: bold;
}
.callToAction,
.callToAction:hover {
  background-color: black;
  color: white;
}