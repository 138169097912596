.root {
  composes: spaced-element from './standard.module.css';
}
@media print {
  .root {
    display: none;
  }
}
.root a {
  text-decoration: none;
}
.crumb::after {
  content: ' » ';
}
.crumb:last-child::after {
  content: '';
}
.crumb:last-child {
  opacity: 0.7;
}
