.root {
  height: 100%;
  background-color: var(--body-background-color);
  color: var(--body-text-color);
}
.root > main {
  width: 100%;
  max-width: 1920px;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .root > main {
    width: calc(100% - 2rem);
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.notFound {
}
.containedElement {
  max-width: 1920px;
  margin: auto;
}
.wideSearchBar {
  background-color: var(--header-background-color);
}
.wideSiteNav {
  background-color: var(--sitenav-background-color);
}
.wideFooter {
  background-color: var(--footer-background-color);
}
.BodySerif {
  font-family: 'Kadwa', serif;
}
.HeadingSerif h1,
.HeadingSerif h2,
.HeadingSerif h3,
.HeadingSerif h4,
.HeadingSerif h5 {
  font-family: 'Source Serif Pro', serif;
}

.BodySansSerif {
  font-family: 'Cabin', sans-serif;
}
.HeadingSansSerif h1,
.HeadingSansSerif h2,
.HeadingSansSerif h3,
.HeadingSansSerif h4,
.HeadingSansSerif h5 {
  font-family: 'Nunito Sans', sans-serif;
}

.BodyUgly {
  font-family: 'Rye', cursive;
}
.HeadingUgly h1,
.HeadingUgly h2,
.HeadingUgly h3,
.HeadingUgly h4,
.HeadingUgly h5 {
  font-family: 'Damion', cursive;
}
