.root {
  max-width: 768px;
}
.eventPreview {
  margin-bottom: 1em;
}
.image {
  object-fit: contain;
  width: 100%;
  max-width: 510px;
  max-height: 510px;
}
.meta,
.heading,
.subheading,
.interstitialActions {
  composes: spaced-element from '../standard.module.css';
}
.meta h3 {
  margin-left: 0;
  margin-right: 0;
}
.when {
  font-size: x-small;
}
