.root {
  width: 100%;
  height: 100%;
  user-select: none;
}
.root a {
  text-decoration: none;
  user-select: none;
}
.root img {
  max-width: 100%;
  max-height: 100%;
}
.root h1 {
  font-size: 1.5em;
  margin: 0;
  padding: 0;
}
