.root :global .DayPicker-Day {
  border-radius: 0;
}
.root :global .DayPicker-Day--disabled {
  cursor: not-allowed;
}
.root :global .DayPicker-Day--locked {
  background-color: #fd2;
  color: #696;
}
.root :global .DayPicker-Day--soldout {
  background-color: #eee;
  color: #999;
}
.root :global .DayPicker-Day--promoted {
  background-color: #3f3;
  color: #696;
}
.root :global
  .DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--soldout:hover {
  background-color: #eee;
}
