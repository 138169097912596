.root {
}
.heading {
  composes: spaced-element from '../standard.module.css';
  margin-top: 1em;
}
.places,
.neighborhoods,
.zips {
  composes: layout-full-width-grid from '../standard.module.css';
}
.place,
.neighborhood,
.zip {
  composes: spaced-element from '../standard.module.css';
}
