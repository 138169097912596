.root {
}
.topLevel {
  font-weight: bold;
}
.label {
}
.path {
  font-size: x-small;
  font-variant: small-caps;
  color: grey;
}
