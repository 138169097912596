.root {
  white-space: nowrap;
}
.root a {
  text-decoration: none;
}
.toggleOpen,
.toggleClosed {
  display: block;
  user-select: none;
  padding: 1em;
  width: 10em;
  text-align: right;
  font-weight: bold;
}
.root .toggleOpen {
  /* More specific than our global a:focus color (inherit) */
  background-color: var(--header-background-color);
  color: var(--header-text-color);
}
.toggleClosed {
  background-color: var(--sitenav-background-color);
  color: var(--sitenav-text-color);
}
.overlay {
  width: calc(100%);
  text-align: right;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
}
.overlay a,
.overlay > div > span {
  display: inline-block;
  user-select: none;
  padding: 0.5em 1em;
}
.overlay > * {
  display: block;
}
.icon {
  width: 20px;
  height: 20px;
  padding-left: 3px;
}
.picture {
  width: 20px;
  height: 20px;
  padding-right: 3px;
}
.name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 1em);
}
