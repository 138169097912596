.root {
  width: 100%;
}
/* https://stackoverflow.com/questions/2460100/remove-the-complete-styling-of-an-html-button-submit */
.root button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.root label {
  display: block;
  text-align: center;
}
.root input {
  display: block;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0.5em;
}
.root button {
  background-color: #556a7a;
  display: block;
  width: 100%;
  padding: 10px 0;
  color: white;
  font-size: larger;
  text-transform: uppercase;
  border-radius: 10px;
}
.root > * {
  margin: 1em 0;
}
.message,
.error {
  border-radius: 10px;
  padding: 10px;
}
.message {
  border: 3px double#556A7A;
}
.error {
  border: 3px solid #9e6565;
}
.error a {
  text-decoration: underline;
  cursor: pointer;
}
@media (min-width: 480px) {
  .root {
    max-width: 480px;
    margin: auto;
  }
  .container {
    margin: 2em;
    border: 1px solid black;
    border-radius: 10px;
  }
}
