.root {
}
.root input {
  box-sizing: border-box;
  width: 100%;
}
.root input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid #555;
  padding: 10px 14px;
  margin-bottom: 1em;
}
/* To override font on stripe input: https://github.com/stripe/react-stripe-elements/issues/179 */
.root :global .StripeElement {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid #555;
  padding: 10px 14px;
  margin-bottom: 1em;
  background-color: white;
}
.root :global .StripeElement--focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
  outline-color: rgb(59, 153, 252);
  outline-offset: -2px;
  outline-style: auto;
  outline-width: 5px;
}
.checkoutForm {
}
.submit {
  display: block;
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
.error {
  color: red;
}
