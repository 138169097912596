/* autoprefixer grid: autoplace */
.root {
  display: grid;
  grid-template:
    'links actions'
    / 1fr auto;
  background-color: var(--sitenav-background-color);
  color: var(--sitenav-text-color);
}
@media print {
  .root {
    display: none;
  }
}
.root > * {
  align-self: center;
}
.links {
  grid-area: links;
  padding: 0.6em;
}
.actionContainer {
  text-align: right;
  grid-area: actions;
  display: inline-block;
}
.actionContainer a {
  font-weight: bold;
}
.actions {
  display: inline-block;
}
.links a, .actionContainer a {
  display: inline-block;
  margin-left: 0.8em;
  text-decoration: none;
}
.links a:first-child, .actionContainer a:first-child {
  margin-left: 0;
}
.links a.active, .actionContainer a.active {
  font-weight: bold;
}
