.CategoryEditPage .EditCategoryForm {
  margin: auto;
  width: 480px;
}
@media (max-width: 480px) {
  .CategoryEditPage .EditCategoryForm {
    width: 100%;
  }
  .CategoryEditPage .EditCategoryForm fieldset {
    padding-left: 0;
    padding-right: 0;
  }
}
.CategoryEditPage .EditCategoryForm fieldset {
  border: none;
}
.CategoryEditPage .EditCategoryForm input,
.CategoryEditPage .EditCategoryForm textarea {
  padding: 0.5em;
  font-size: 1.2em;
  margin-bottom: 0.1em;
  border: 1px solid #ccc;
  width: calc(100% - 1em);
}
.CategoryEditPage .EditCategoryForm textarea {
  height: 5em;
}
.CategoryEditPage .EditCategoryForm button {
  font-size: 1.2em;
  padding: 0.5em;
}
.CategoryEditPage .EditCategoryForm .Error {
  color: red;
  font-weight: bold;
}
