.root > table {
  width: 100%;
  border-collapse: collapse;
}
.root > table > thead > tr > td,
.root > table > tbody > tr > td {
  padding: 1em;
  border-bottom: 1px solid #666;
}
.root > table > thead > tr > td {
  background-color: #eee;
  font-weight: bold;
}
.root > table > tbody > tr:last-child > td {
  border-bottom: none;
}
.root > table > thead > tr > td:last-child,
.root > table > tbody > tr > td:last-child {
  text-align: right;
}
.empty {
  text-align: left;
}
.price {
  font-family: monospace;
}
