/* autoprefixer grid: autoplace */
.spaced-element {
  margin: 0 0.6rem 1em 0.6rem;
}
.spaced-bottom {
  margin-bottom: 1rem;
}

.layout-right-rail {
  margin-bottom: 1rem;
}
.layout-right-rail > *:first-child {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .layout-right-rail {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
  }
  .layout-right-rail > *:first-child {
    margin-bottom: inherit;
  }
}
.layout-right-rail > *:nth-child(2) > * {
  margin-bottom: 1rem;
}
.layout-right-rail > *:nth-child(2) > *:last-child {
  margin-bottom: inherit;
}

/* https://css-tricks.com/css-grid-in-ie-faking-an-auto-placement-grid-with-gaps/ */
.layout-left-rail-grid {
  overflow: hidden;
  margin-bottom: 1rem;
}
.layout-left-rail-grid > * {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem -1rem 0;
  box-sizing: border-box;
}
.layout-left-rail-grid > * > *,
.layout-left-rail-grid > * > *::before,
.layout-left-rail-grid > * > *::after {
  box-sizing: inherit;
}
.layout-left-rail-grid > * > * {
  width: 100%;
  border: 0 solid transparent;
  border-width: 0 1rem 1rem 0;
  background-clip: padding-box;
}
@media (min-width: 992px) {
  .layout-left-rail-grid > * > * {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .layout-left-rail-grid > * > * {
    width: 33.33333333%;
  }
}

/* https://css-tricks.com/css-grid-in-ie-faking-an-auto-placement-grid-with-gaps/ */
.layout-full-width-grid {
  overflow: hidden;
  margin-bottom: 1rem;
}
.layout-full-width-grid > * {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem -1rem 0;
  box-sizing: border-box;
}
.layout-full-width-grid > * > *,
.layout-full-width-grid > * > *::before,
.layout-full-width-grid > * > *::after {
  box-sizing: inherit;
}
.layout-full-width-grid > * > * {
  width: 100%;
  border: 0 solid transparent;
  border-width: 0 1rem 1rem 0;
  background-clip: padding-box;
}
@media (min-width: 768px) {
  .layout-full-width-grid > * > * {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .layout-full-width-grid > * > * {
    width: 33.33333333%;
  }
}
@media (min-width: 1200px) {
  .layout-full-width-grid > * > * {
    width: 25%;
  }
}
