/* autoprefixer grid: autoplace */
.root {
  padding: 2em 0.5em 2em 0.5em;
  max-width: 1200px;
  margin: auto;
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
}
@media print {
  .root {
    display: none;
  }
}
@media (min-width: 768px) {
  .root {
    padding: 4em 1em 4em 1em;
  }
}
.logo {
  text-align: center;
}
@media (min-width: 768px) {
  .logo {
    text-align: left;
  }
}
.logo img {
  max-height: 3em;
  min-height: 2em;
}
.siteLinks ul,
.siteLinks h4 {
  margin: 0;
  padding: 0;
}
.siteLinks li {
  list-style: none;
  padding: 0.25em 0 0.25em 0;
}
.platformLinks {
  text-align: center;
  border-top: 1px solid;
  padding-top: 2em;
  margin-top: 4em;
  font-size: 0.85em;
  border-color: var(--footer-text-color);
}
.platformLinks span {
  display: inline-block;
  margin-right: 0.5em;
  margin-left: 0.5em;
}
.root a {
  text-decoration: none;
}
.root a:hover {
  text-decoration: underline;
}
.logo a:hover {
  text-decoration: none;
}

.cols1 > *:nth-child(1),
.cols2 > *:nth-child(1),
.cols3 > *:nth-child(1),
.cols4 > *:nth-child(1) {
  grid-area: logo;
}
.cols1 > *:nth-child(2),
.cols2 > *:nth-child(2),
.cols3 > *:nth-child(2),
.cols4 > *:nth-child(2) {
  grid-area: col1;
}
.cols2 > *:nth-child(3),
.cols3 > *:nth-child(3),
.cols4 > *:nth-child(3) {
  grid-area: col2;
}
.cols3 > *:nth-child(4),
.cols4 > *:nth-child(4) {
  grid-area: col3;
}
.cols4 > *:nth-child(5) {
  grid-area: col4;
}
.cols1 {
  display: grid;
  grid-template:
    'logo'
    'col1'
    / 1fr;
}
.vybLogo {
  height: 2.5em;
  vertical-align: text-bottom;
  margin-bottom: -0.75em;
}
.vybLogo path {
  fill: currentColor;
}
@media (min-width: 768px) {
  .cols1 {
    display: grid;
    grid-template: 'logo col1' / 50% 1fr;
  }
}
.cols2 {
  display: grid;
  grid-template:
    'logo logo'
    'col1 col2'
    / 1fr 1fr;
}
@media (min-width: 768px) {
  .cols2 {
    display: grid;
    grid-template: 'logo col1 col2' / 50% 1fr 1fr;
  }
}
.cols3 {
  display: grid;
  grid-template:
    'logo logo'
    'col1 col2'
    'col3 .'
    / 1fr 1fr;
}
@media (min-width: 768px) {
  .cols3 {
    display: grid;
    grid-template: 'logo col1 col2 col3' / 50% 1fr 1fr 1fr;
  }
}
.cols4 {
  display: grid;
  grid-template:
    'logo logo'
    'col1 col2'
    'col3 col4'
    / 1fr 1fr;
}
@media (min-width: 768px) {
  .cols4 {
    display: grid;
    grid-template: 'logo col1 col2 col3 col4' / 50% 1fr 1fr 1fr 1fr;
  }
}
@supports (grid-row-gap: 1em) {
  .cols2,
  .cols3,
  .cols4 {
    grid-row-gap: 1em;
  }
}
