/* autoprefixer grid: autoplace */
.root {
}
.heading,
.subHeading,
.noUpcoming {
  composes: spaced-element from '../standard.module.css';
}
.event {
  margin-bottom: 1em;
}
@media (min-width: 480px) {
  .event {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0.5em;
  }
}
.image {
  width: 100%;
  max-width: 480px;
}
.unpublished {
  font-weight: lighter;
  text-align: center;
  border: 1px solid;
  padding: 1em;
  margin-bottom: 0.5em;
}
.title {
  font-size: larger;
}
.actions {
  margin-top: 0.5em;
}
.actions > * {
  margin-right: 1em;
}
.noUpcoming > a {
  margin-top: 1em;
}
